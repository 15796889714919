import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";

// this is a copy of the styles from hero-header -- should go in shared folder or smth
const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const NeumorphicInset = css`
  box-shadow: inset 2px 2px 3px #00000060,
              inset -10px -30px 25px #ffffff05;
`;

const PostPreviewsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const PostPreviewItems = styled.ul`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  list-style: none;

  flex: 1;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  padding: 50px;
`;

const PostPreviewItem = styled.li`
  height: 100%;
`;

const PostPreviewContainer = styled.div`
  ${Neumorphic}

  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: linear-gradient(135deg, rgba(255,255,255,0.025) 0%, rgba(0,0,0,0.01) 100%);
  height: 100%;
`;

const PostPreviewCoverImage = styled(GatsbyImage)`
  ${NeumorphicInset}

  width: 100%;
  height: 200px;

  object-fit: cover;

  border-radius: 15px 15px 0 0;

  padding: 1px;
  box-sizing: border-box;

  -webkit-filter: brightness(100%);

  &:hover {
    -webkit-filter: brightness(80%);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
`;

const PostPreviewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

const PostPreviewTitle = styled.h2`
  display: -webkit-box;
  margin: 0;
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: unset;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    -webkit-line-clamp: 3;
  }
`;

const PostPreviewSubtitle = styled.p`
  margin: 0;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;

  max-height: 140px;
`;

const PostPreviewDateContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PostPreviewDatePosted = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const ReadMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  flex: 1;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
`;

const ReadMoreLink = styled(Link)`
  ${Neumorphic}

  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  border: none;
  padding: 10px;
  min-width: 150px;
  max-width: 200px;
  cursor: pointer;
  color: ${props => props.theme.buttonText};
  background: linear-gradient(135deg, rgba(255,255,255,0.025) 0%, rgba(0,0,0,0.01) 100%), 
              ${props => props.theme.buttonBackground};

  @media (max-width: 768px) {
    align-self: flex-start;
  }

  &:hover {
    ${NeumorphicInset}
  }
`;

export {
  PostPreviewsContainer,
  PostPreviewItems,
  PostPreviewItem,
  PostPreviewContainer,
  PostPreviewCoverImage,
  PostPreviewContentContainer,
  PostPreviewTitle,
  PostPreviewSubtitle,
  PostPreviewDateContainer,
  PostPreviewDatePosted,
  ReadMoreContainer,
  ReadMoreLink,
};