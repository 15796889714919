import React, { useContext } from "react";
import * as S from "./hero-header.styles";
import { StaticImage } from 'gatsby-plugin-image';
import { ThemeContext, ThemeProvider } from "styled-components";

const HeroHeader = () => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <S.HeroHeaderContainer>
        
        <S.HeroImageContainer>
          <StaticImage
            src="./images/spaceman.png"
            alt="spaceman"
            quality={100}
            loading="eager"
            placeholder="tracedSVG"
          />
        </S.HeroImageContainer>
        
        <S.MainContent>
          <S.MainHeading>
            Hey, I'm Andrew!
          </S.MainHeading>

          <S.SubHeading>
            Your friendly neighbourhood full stack web dev.
          </S.SubHeading>
        
          <S.InnerDiv>
            <S.Paragraph>
              I love creating unique web experiences that utilize the latest and greatest technologies.
            </S.Paragraph>

            <S.Paragraph>
              Interested? You should check out my blog! 👇
            </S.Paragraph>

            <S.CTAButton 
              to="/blog" 
              label="Go To Blog" 
            />

          </S.InnerDiv>

        </S.MainContent>
      </S.HeroHeaderContainer>
    </ThemeProvider>
  );
};

export default HeroHeader;