import React from "react";
import * as S from "./post-previews.styles";
import { useStaticQuery, graphql } from "gatsby";
import PostPreview from "./post-preview";

// add limit: 3 when pagination implemented 
const postPreviewsQuery = graphql`
  query {
    allMdx(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          date(formatString: "dddd, MMMM Do YYYY")
          title
          subtitle
          hero_image_alt
        }
        id
        slug
        excerpt(pruneLength: 120, truncate: true)
      }
    }
  }
`;

const PostPreviews = () => {
  const {
    allMdx: { 
      nodes: posts 
    }, 
  } = useStaticQuery(postPreviewsQuery);

  return (
    <S.PostPreviewsContainer>
      <S.PostPreviewItems>
        {posts.map(post => (
          <PostPreview key={post.id} post={post} />
        ))}
      </S.PostPreviewItems>
    </S.PostPreviewsContainer>
  )
};

export default PostPreviews;