import styled from "styled-components";
import InternalButton from "../shared/buttons";

const HeroHeaderContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  min-height: 80vh;
  width: 80vw;
  align-self: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 1200px) {
    position: static;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  gap: 25px;

  @media (max-width: 1200px) {
    text-align: center;
    align-items: center;
  }
`;

const MainHeading = styled.p`
  text-transform: uppercase;
  font-size: 3rem;
`;

const SubHeading = styled.p`
  font-size: 1.5rem;
`;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;

  gap: 25px;
`;

// these should be shared styles but will be here for now until get there
const Paragraph = styled.p``;

const CTAButton = styled(InternalButton)`
  width: 150px;
  align-self: flex-end;

  @media (max-width: 1200px) {
    align-self: center;
  }
`;

export {
  HeroHeaderContainer,
  HeroImageContainer,
  MainContent,
  MainHeading,
  SubHeading,
  InnerDiv,
  Paragraph,
  CTAButton,
};